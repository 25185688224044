import { Link } from "gatsby";
import styled from "@emotion/styled";
import _configBase from "../../../configs/_configBase";
import { colors, fonts, mediaQueries } from "../../../configs/_configSite";

const styles = `
  ${_configBase};
  font-family: ${fonts.primary};
  text-decoration: none;
  font-size: 2rem;
  background-color: ${colors.gold};
  color: #fff;
  margin-top: 15px;
  padding: 0.5rem 1rem;
  margin-left: auto;
  float: right;
  display: block;
  transition: all 0.2s;
  &:hover {
    background-color: #fff;
    color: ${colors.gold};
  }
  @media (max-width: ${mediaQueries.xs}px) {
    float: none;
    margin: 0 auto;
    width: max-content;
    font-size: 12px;
  }
`;

export const LinkDonationInt = styled(Link)`
  ${styles}
`;

export const LinkDonationExt = styled.a`
  ${styles}
`;
